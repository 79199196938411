$color-shade-a: #34C6B4;
$color-shade-b: #00FFE6;
$color-shade-c: #00FFE6;
$color-shade-d: #00CFFF;
$color-shade-e: #8998F1;

$color-darkblue: #1b1f2d;

$background-dark: #1b1f2d;
$background-light: #fefefe;
$color-onlight: #1a1a1a;
$color-ondark: #f5f5f5;

$font-copytext: 'Roboto', Arial, Helvetica, sans-serif;
$font-logo: 'Bungee Inline', Arial, Helvetica, sans-serif;
$font-handwritten-one: 'Indie Flower', Arial, Helvetica, sans-serif;
$font-handwritten-two: 'Handlee', Arial, Helvetica, sans-serif;

$max-width-content: 1440px;

$breakpoints: (
  small: 750px,
  medium: 900px,
  large: 1440px
);

@mixin respond-to($name) {
  @if not map-has-key($breakpoints, $name) {
    @warn "Warning: `#{$name}` is not a valid breakpoint name.";
  } @else {
    @media (min-width: map-get($breakpoints, $name)) {
      @content;
    }
  }
}
/************************************************
* Typography Styles
************************************************/

// FONTS
$font-regular: Arial, Helvetica, sans-serif;
$font-regular-variable: Arial;
$font-italic: Arial, Helvetica, sans-serif;
$font-bold: Arial, Helvetica, sans-serif;

// DECLARATIONS

/* Example typography declaration

berlin: (
  fontSizes: (140px, 120px, 80px, 60px),
  fontFamily: $font-regular,
  fontWeight: bold,
  lineHeights: (1.5, 1.5, 1.5, 1.5),
  fontSmoothing: antialiased,
  letterSpacing: 1.5px,
  textTransform: uppercase
),

*/

$font-specifications: (
  esslingen: (
    fontSizes: (3rem, 3rem, 3rem, 3rem),
    fontFamily: $font-regular,
    fontWeight: 650,
    lineHeights: (1.2, 1.2, 1.2, 1.2),
  ),
  berlin: (
    fontSizes: (2rem, 2rem, 2rem, 2rem),
    fontFamily: $font-regular,
    fontWeight: 650,
    lineHeights: (1.2, 1.2, 1.2, 1.2),
  ),
  potsdam: (
    fontSizes: (1rem, 1rem, 1rem, 1rem),
    fontFamily: $font-regular,
    lineHeights: (1.2, 1.2, 1.2, 1.2),
    textTransform: uppercase,
  ),
  bochum: (
    fontSizes: (1rem, 1rem, 1rem, 1rem),
    fontFamily: $font-regular,
    lineHeights: (1.2, 1.2, 1.2, 1.2),
  ),
  leipzig: (
    fontSizes: (1.5rem, 1.5rem, 1.5rem, 1.5rem),
    fontFamily: $font-italic,
    lineHeights: (1.2, 1.2, 1.2, 1.2),
  ),
  



  stuttgart: (
    fontSizes: (64px, 64px, 64px, 64px),
    fontFamily: $font-regular,
    lineHeights: (1, 1, 1, 1),
  ),
  mannheim: (
    fontSizes: (64px, 64px, 64px, 64px),
    fontFamily: $font-bold,
    fontWeight: 650,
    lineHeights: (1.2, 1.2, 1.2, 1.2),
  ),
  frankfurt: (
    fontSizes: (56px, 56px, 56px, 56px),
    fontFamily: $font-regular,
    fontWeight: bold,
    lineHeights: (1, 1, 1, 1),
  ),
 
  dresden: (
    fontSizes: (32px, 32px, 32px, 32px),
    fontFamily: $font-regular,
    lineHeights: (1, 1, 1, 1),
  ),
  
  essen: (
    fontSizes: (20px, 20px, 20px, 20px),
    fontFamily: $font-regular,
    fontWeight: bold,
    lineHeights: (1, 1, 1, 1),
  ),
  kiel: (
    fontSizes: (16px, 16px, 16px, 16px),
    fontFamily: $font-regular,
    lineHeights: (1, 1, 1, 1),
  ),
);

// FUNCTIONS

@function map-deep-get($map, $keys...) {
  @each $key in $keys {
    $map: map-get($map, $key);
  }

  @return $map;
}

@mixin typography($name) {
  @if not map-has-key($font-specifications, $name) {
    @warn "Warning: `#{$name}` is not a valid font name.";
  }

  @else {
    $family: map-deep-get($font-specifications, $name, 'fontFamily');
    $sizes: map-deep-get($font-specifications, $name, 'fontSizes');
    $heights: map-deep-get($font-specifications, $name, 'lineHeights');
    $spacing: map-deep-get($font-specifications, $name, 'letterSpacing');
    $transform: map-deep-get($font-specifications, $name, 'textTransform');
    $weight: map-deep-get($font-specifications, $name, 'fontWeight');
    $smoothing: map-deep-get($font-specifications, $name, 'fontSmoothing');

    font-family: $family;
    font-size: nth($sizes, 4);
    font-weight: $weight;
    line-height: nth($heights, 4);

    @if $transform {
      text-transform: $transform;
    }

    @if $spacing {
      letter-spacing: $spacing;
    }

    @if $smoothing {
      -webkit-font-smoothing: $smoothing;
    }

    @include respond-to("small") {
      font-size: nth($sizes, 3);
      line-height: nth($heights, 3);
    }

    @include respond-to("medium") {
      font-size: nth($sizes, 2);
      line-height: nth($heights, 2);
    }

    @include respond-to("large") {
      font-size: nth($sizes, 1);
      line-height: nth($heights, 1);
    }
  }
}
.m-Hero {
  // background-color: #fff;
  // color: #fff;
  border: 6px solid #f5f5f5;
  border-radius: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  // height: 200px;
  overflow: hidden;
  position: relative;

  img {
    border-radius: 16px;
    display: block;
    width: 100%;
    height: auto;
    // position: absolute;
    // height: 100%;
    // left: 0px;
    // top: 0px;
    // object-fit: cover;
    // transform: translate(-12%, 0);
  }

  @include respond-to("medium") {
    img {
      height: 100%;
      transform: none;
      width: 100%;
    }
  }
}